// constant file
export const LOGIN = "LOGIN"
export const REGISTER = "REGISTER"
export const USRNAME = `usernameOrEmail`
export const PASS = `password`
//export const POST_LOGIN = `/login/auth/`
export const POST_LOGIN = `/login/auth/generatetoken`
export const FETCH_SEARCH = `/search-api/data`
export const FETCH_FPP = `/fpp-api/all-info`
export const FETCH_CUP = `/cup-api/all-info`
export const FETCH_COP = "cop-api/all-info"
export const FETCH_CMN = "cm-api/all-info"
export const FETCH_RMP = "rmp-api/all-info"
export const FETCH_FOP = "fop-api/all-info"
export const FETCH_PMP = "pmp-api/all-info"
export const FETCH_IP = "ip-api/all-info"
export const FETCH_TUP = "tup-api/all-info"
export const FETCH_APP = "app-api/all-info"
export const FETCH_PAP = "pap-api/all-info"
export const FETCH_MPAP = "mpap-api/all-info"
export const FETCH_APMP = "apmp-api/all-info"
export const FETCH_IPP = "ipp-api/all-info"
export const FETCH_MIP = "mip-api/all-info"
export const FETCH_MCOP = "mcop-api/all-info"
export const FETCH_MCUP = "mcup-api/all-info"
export const FETCH_CDB = "cdb-api/all-info"
export const FETCH_ASL = "asl-api/all-info"
export const FETCH_PIP = "pip-api/all-info"
export const FETCH_OPP = "opp-api/all-info"
export const FETCH_FAB = "fab-api/all-info"
export const FETCH_IRMS = "irms-api/all-info"
export const FETCH_MRMP = "mrmp-api/all-info"
export const FETCH_MPP = "mpp-api/all-info"
export const FETCH_MPMP = "mpmp-api/all-info"
export const FETCH_DPP = "dpp-api/all-info"
export const FETCH_MEP = "mep-api/all-info"
export const FETCH_SEP = "sep-api/all-info"
export const FETCH_POA = "poa-api/all-info"
export const FETCH_ARMP = "armp-api/all-info"
export const FETCH_IMP = "imp-api/all-info"
export const FETCH_MCP = "mcp-api/all-info"
export const FETCH_VENDOR = `/search-api/data/vendor/companies`
export const VENDOR_RESULTS = `/search-api/data/vendor/type`
export const VENDOR_RESULT = `/search-api/data/vendornextgen/type`
export const PDF_DOWNLOAD_LINK = "pdf-api/all-info"
export const DOWNLOAD_LINK = "fcs-api/download?ObjectId="
export const REF_DOWNLOAD_LINK = "fcs-api/download/ref?ObjectId="
export const FETCH_COMMONPAGE_DATA = "search-api/data/common/type"
export const FETCH_PING = `/authentication/generatetoken`
export const REFRESH_PING = `/authentication/refreshtoken`
export const LOGOUT_PING = `/authentication/logout`
export const LOG_LOGOUT_API = "authentication/loglogout"
export const SAVE_PREF = `/specanywhere/saveUserData`
export const FETCH_PREF = `/specanywhere/fetchUserData/userId`
export const GENDOCFILE_DOWNLOAD = "/fcs-api/download/file"
export const POA_REFDOC_DOWNLOAD = "fcs-api/download/files"
export const FETCH_PSUB = `/psub-api/all-info`
export const FETCH_MPS = `/mps-api/all-info`
export const FETCH_FC = `/fc-api/all-info`
export const FETCH_CL = `/isa-api/all-info`
export const FETCH_PQR = `/pqr-api/all-info`
export const FETCH_UNFC = `/unfc-api/all-info`
export const FETCH_UNMPS = `/unmps-api/all-info`
export const FETCH_BSF = `/bsf-api/all-info`
export const FETCH_SIS = `/sis-api/all-info`
export const FETCH_SWP = `/swp-api/all-info`
export const FETCH_SML = `/smrtlbl-api/all-info`
export const FETCH_SBV = `/sftprt-api/all-info`
export const FETCH_EMP = `/emp-api/all-info`
export const FETCH_REPORT_DATA = `dsmrequest-api/reportHomePage`
export const FETCH_REPORT_TEMPLATE = `dsmrequest-api/templateHomePage`
export const REPORT_SUBMIT = `submitreport-api/submit`
export const DSM_DELETE = `submitreport-api/delete`
export const DELETE_SUBSCRIPTION = `/dssubmit-api/all-info/deleteDS`
export const FETCH_DS_FORM_DATA = `/dsrequest-api/all-info`
export const FETCH_SEARCH_3D_RESULT = `/dsrequest-api/all-info/search`
export const DS_SUBMIT = `dssubmit-api/all-info/submit`
export const FETCH_DYNAMIC_SUB = `dsrequest-api/all-info`
export const MOVE_REMOVE_API = `/search-api/ebpViewPart`
export const SEARCH_WHERE_USED = `/search-api/nextGenData`
export const WHERE_USED=`/search-api/whereUsedData`
export const EMAIL_DATA=`/search-api/getEbpEmails`
export const EMAIL_PREFERENCE = `/search-api/update/preference`
export const SELECTED_VENDOR_PREFERENCE=`/search-api/getVendors`
export const EBP_VIEW_PDF_DOWNLOAD = `pdf-api/ebp/all-info/`
export const FETCH_VENDOR_PREFERENCE=`/search-api/getVendors`
export const UPDATE_VENDOR_PREFERENCE=`/search-api/updateVendors`
export const FETCH_HELP_DSM =
   "https://intheknow.pg.com/pages/how-to-create-dsm-reports-in-spec-reader"
export const FETCH_VOICEIT = "https://VoiceIt.pg.com?deeplinkID=184"
export const FETCH_CADVIEWER="https://pgplm.trivantis.com/shared/pg/course/ARTIOS-1010-JA/sca/ArtiosCADViewer%202.exe"
export const FETCH_SUBMITTICKET="https://pgglobalenterprise.service-now.com/itsm?id=itsm_sc_cat_item&sys_id=2e841f45dbb9c3c047a3f1041d96199b"
export const PREF_MSG =
   "Saved preferences does not exists for this object. Please select items from filters to view the data."

export const DefaultKeys = {
   attributeList: "Attributes",
   Attributes: "Attributes",
   attributeObject: "Attributes",
   attributesObject: "Attributes",
   attributes: "Attributes",
   organizationsData: "Organizations",
   organizationsObject: "Organizations",
   organizations: "Organizations",
   organization: "Organizations",
   // ownership: "Ownership",
   // ownershipObject: "Ownership",
}

export const FPPKeys = {
   attributesObject: "Attributes",
   smartLabelRollupData: "Smart Label Rollup Data",
   dangerousGoodsClassification: "Dangerous Goods Classification",
   globalHarmonizedStandard: "Global Harmonized Standard",
   ingredientStatement: "Ingredient Statement",
   storageTransportDataObject: " Storage, Transportation, Labeling Assessment Data",
   stabilityResults: "Stability Document",
   warHouseClassification: "Warehouse Classification",
   batteryRollUp: "Battery Roll Up",
   batteryCalculation: "Battery Calculation",
   sapBomasFed: "SAP BOM as Fed ",
   notes: "Notes",
   cupArtwork: "CUP Artwork",
   marketOfSale: "Market Of Sale",
   registrationDetails: "Registration Details",
   billOfMaterials: "Bill Of Materials",
   substitutes: "Substitutes",
   billOfMaterialsCustomerUnit: "Bill of Materials Customer Unit",
   substitutesCustomerUnit: "Substitutes Customer Unit",
   bomInnerPack: "Bill of Materials Inner Pack",
   substitutesInnerPack: "Substitutes Inner Pack",
   billOfMaterialsConsumerUnit: "Bill of Materials Consumer Unit",
   substitutesConsumerUnit: "Substitutes Consumer Unit",
   billOfMaterialsTransportUnit: "Bill of Materials Transport Unit",
   substitutesTransportUnit: "Substitutes Transport Unit",
   billOfMaterialsMasterCustomerUnit: "Bill of Materials Master Customer Unit",
   substitutesMasterCustomerUnit: "Substitutes Master Customer Unit",
   bomMasterInnerPack: "Bill of Materials Master Inner Pack",
   substitutesMasterInnerPack: "Substitutes Master Inner Pack",
   bomMasterConsumerUnit: "Bill of Materials Master Consumer Unit",
   substitutesMasterConsUnit: "Substitutes Master Consumer Unit",
   relatedSpecifications: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   weightsDimensionsObject: "Weights & Dimensions",
   packingUnit: "Packing Unit",
   transportUnitHead: "Transport Unit",
   relatedATS: "Related ATS",
   baseCodeDetails: "Base Code Details",
   substituteBaseCodeDetails: "Substitute Base Code Details",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview ",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClaases: "Security Classes",
   organizationsObject: "Organizations",
   files: "Files",
   certifications: "Certifications",
   derivedParts: "Related Parts",
   uniqueFormulaIdentifier: "Unique Formula Identifier",
   billOfMaterialsFinishedProductPart: "Bill of Materials Finished Product Part",
   packagingCertifications: "Packaging Certifications",
   whereUsed: "Where Used",
}

export const FABKeys = {
   attributeObject: "Attributes",
   weightCharacteristics: "Weight Characteristic",
   notes: "Notes",
   sapBomFeds: "SAP BOM as Fed",
   marketOfSales: "Market of Sale",
   registrationDetails: "Registration Details",
   substanceMaterials: "Materials & Composition",
   billOfMaterial: "Bill of Materials",
   substitutions: "Substitutes",
   relatedSpecification: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   plants: "Plants",
   alternates: "Alternates",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   relatedATS: "Related ATS",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipclasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   packagingCertifications: "Certifications",
   sustainability: "Sustainability",
   whereUsed: "Where Used"
}

export const APMPKeys = {
   attributeObject: "Attributes",
   weightCharacterstics: "Weight Characteristic",
   substanceMaterials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   alternates: "Alternates",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   derivedParts: "Derived Parts",
   substances: "Substances",
   sustainability: "Sustainability",
   whereUsed:"Where Used"
}

export const ARMPKeys = {
   attributeObject: "Attributes",
   materialsAndComposition: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   certifications: "Certifications",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   derivedParts: "Related Parts",
   notes: "Notes",
   weightCharcteristics: "Weight Characteristics",
   relatedATS: "Related ATS",
   whereUsed:"Where Used"
}

export const APPKeys = {
   attributeObject: "Attributes",
   weightCharcteristic: "Weight Characteristic",
   dangerousGoodsClassification: "Dangerous Goods Classification",
   globalHarmonizedStandard: "Global Harmonized Standard",
   ingredientStatement: "Ingredient Statement",
   storageTransportationLabelingAssessmentData:
      "Storage, Transportation, Labeling Assessment Data",
   notes: "Notes",
   productPartStabilityDocument: "Product Part Stability Document",
   masterPartStabilityDocument: "Master Part Stability Document",
   sapBOMFed: "SAP BOM as Fed",
   chemicalAndPhysicalPropertiesGHCDGCClassification:
      "Chemical and Physical Properties – GHS/DGC Classification",
   chemicalAndPhysicalPropertiesWarehouseClassification:
      "Chemical and Physical Properties – Warehouse Classification",
   marketClearance: "Market Clearance",
   materialsAndCompositions: "Materials & Composition",
   billOfMaterials: "Bill Of Materials",
   substitutions: "Substitutes",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   characteristics: "Characteristics",
   plants: "Plants",
   alternates: "Alternates",
   relatedATS: "Related ATS",
   materialProduced: "Materials Produced",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   smartLabel: "Smart Label",
   certifications: "Certifications",
   certificationValidation: "Certification Validation",
   designParameter: "Design Parameter",
   whereUsed:"Where Used"
}

export const ASLKeys = {
   attributeObject: "Attributes",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   supplierInformations: "Supplier Information",
   pgInternalInformtions: "P&G Internal Information",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   whereUsed:"Where Used"
}

export const CDBKeys = {
   attributeObject: "Attributes",
   organizationObject: "Organizations",
   referenceDocuments: "Reference Documents",
   packageQualityParameters: "Package Quality Parameters",
   productQualityParameters: "Product Quality Parameters",
   ownershipObject: "Ownership",
   plants: "Plants",
   whereUsed:"Where Used"
}

export const COPKeys = {
   attributes: "Attributes",
   weightCharacterstics: "Weight Characteristic",
   storageTransportData: "Storage, Transportation, Labeling Assessment Data",
   notes: "Notes",
   marketClearance: "Market Clearance",
   billofMaterials: "Bill Of Materials",
   subStitutes: "Substitutes",
   relatedSpecifications: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownership: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   relatedATS: "Related ATS",
   organizations: "Organizations",
   files: "Files",
   derivedParts: "Related Parts",
   // derivedTo:"Derived To" ,
   // derivedFrom:"DerivedFrom" ,
   certifications: "Certifications",
   sustainability: "Sustainability",
   gpsAssessments: "GPS Assessments",
   whereUsed:"Where Used"

}

export const CUPKeys = {
   attributes: "Attributes",
   weightCharacterstics: "Weight Characteristic",
   storageTransportData: "Storage, Transportation, Labeling Assessment Data",
   notes: "Notes",
   billOfMaterials: "Bill Of Materials",
   subStitutes: "Substitutes",
   relatedSpecifications: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   relatedATS: "Related ATS",
   lifecycleApproval: "Lifecycle/Approval Powerview",
   ownership: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizations: "Organizations",
   files: "Files",
   derivedParts: "Related Parts",
   whereUsed:"Where Used",
}

export const FOPKeys = {
   attributes: "Attributes",
   weightCharacteristic: "Weight Characteristic",
   dangerousGoodsClassification: "Dangerous Goods Classification",
   globalHarmonizedStandard: "Global Harmonized Standard",
   ingredientStatement: "Ingredient Statement",
   storageTransportationLabelingAssessmentData:
      "Storage, Transportation, Labeling Assessment Data",
   chemicalAndPhysicalPropertiesGHCDGCClassification:
      "Chemical and Physical Properties – GHS/DGC Classification",
   chemicalAndPhysicalPropertiesWarehouseClassification:
      "Chemical and Physical Properties – Warehouse Classification",
   chemicalAndPhysicalPropertiesEnginuityLegacyData:
      "Chemical and Physical Properties – Enginuity/Legacy Data",
   productPartStabilityDocument: "Product Part Stability Document",
   masterPartStabilityDocument: "Master Part Stability Document ",
   sapBomAsFed: "SAP BOM as Fed",
   formulationProcess: "Formulation Process",
   formulationProcessFormula: "Formulation Process - Formula",
   substitutes: "Substitutes",
   marketClearance: "Market Clearance",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharcteristic: "Performance Characteristic",
   materialsAndCompositions: "Materials & Composition",
   plants: "Plants",
   relatedATS: "Related ATS",
   materialProduced: "Materials Produced",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownership: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organization: "Organizations",
   fileSection: "Files",
   gpsAssessments: "GPS Assessments",
   smartLabel: "Smart Label",
   certifications: "Certifications",
   certificationValidation: "Certification Validation",
   chemicalAndPhysicalProperties: "Chemical and Physical Properties",
   stabilityResults: "Stability Results",
   derivedParts: "Derived Parts",
   masterAttribute: "Master Attributes",
   whereUsed:"Where Used",
}

export const IPKeys = {
   attributesObject: "Attributes",
   weightCharacterstics: "Weight Characteristic",
   notes: "Notes",
   billOfMaterials: "Bill Of Materials",
   substitutes: "Substitutes",
   relatedSpecifications: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   relatedATS: "Related ATS",
   plants: "Plants",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationsObject: "Organizations",
   files: "Files",
   whereUsed:"Where Used",
}

export const IPPKeys = {
   attributesObject: "Attributes",
   weightCharacteristic: "Weight Characteristic",
   notes: "Notes",
   sapBomAsFed: "SAP BOM as Fed",
   materialsAndComposition: "Materials & Composition",
   billOfMaterials: "Bill Of Materials",
   substitution: "Substitutes",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   relatedATS: "Related ATS",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationsObject: "Organizations",
   files: "Files",
   whereUsed:"Where Used",
}

export const MCOPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   billOfMaterials: "Bill Of Materials",
   substitutions: "Substitutes",
   relatedSpecification: "Related Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   plants: "Plants",
   derivedParts: "Derived Parts",
   relatedATS: "Related ATS",
   whereUsed:"Where Used",
}

export const MCUPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   billOfMaterials: "Bill Of Materials",
   relatedSpecification: "Related Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristics: " Performance Characteristic ",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   derivedParts: "Derived Parts",
   substitutions: "Substitutes",
   relatedATS: "Related ATS",
   whereUsed:"Where Used",
}

export const MEPKeys = {
   attributeObject: "Attributes",
   reach: "REACH",
   stabilityDocument: "Stability Document",
   materialsAndComposition: "Materials & Composition",
   substancesMaterials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   enterpriseParts: "Enterprise Parts",
   equivalentSEP: "Equivalent SEP",
   certifications: "Certifications",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   packagingCertifications: "Certifications",
   whereUsed:"Where Used",
   // billOfMaterial: "Bill Of Materials",
   // substitutes: "Substitutes",
}

export const MIPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   billOfMaterials: "Bill Of Materials",
   subStitutes: "Substitutes",
   relatedSpecifications: "Related Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   relatedATS: "Related ATS",
   derivedParts: "Derived Parts",
   whereUsed:"Where Used",
}

export const MPAPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   billOfMaterials: "Bill Of Materials",
   subStitutes: "Substitutes",
   relatedSpecifications: "Related Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipclasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   derivedParts: "Derived Parts",
   relatedATS: "Related ATS",
   whereUsed:"Where Used",
}

export const OPPKeys = {
   attributeObject: "Attributes",
   substancesMaterials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   sustainability: "Sustainability",
   whereUsed: "Where Used"
   // derivedParts: "Derived Parts",
   // // derivedTo: "Derived To",
   // // derivedFrom: "Derived From",
   // masterAttributes: "Master Attributes",
   // substances: "Substances",
}

export const PAPKeys = {
   attributeObject: "Attributes",
   weightCharacteristic: "Weight Characteristics",
   sapBomAsFed: "SAP BOM as Fed",
   notes: "Notes",
   marketsOfSaleObject: "Market Of Sale",
   registrationDetails: "Registration Details",
   billOfMaterials: "Bill Of Materials",
   substitution: "Substitutes",
   relatedSpecification: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   alternates: "Alternates",
   relatedATS: "Related ATS",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview ",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   // derivedParts: "Related Parts",
   // masterAttributes: "Master Attributes",
   certifications: "Certifications",
   substancesMaterials: "Materials & Composition",
   whereUsed: "Where Used"
}

export const PIPKeys = {
   attributeObject: "Attributes",
   weightCharateristic: "Weight Characteristic",
   relatedSpecifications: "Related Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   materials: "Materials & Composition",
   whereUsed: "Where Used",
   //    derivedParts: "Derived Parts",
}
export const MRMPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   derivedParts: "Derived Parts",
   whereUsed: "Where Used"
}
export const PMPKeys = {
   attributesObject: "Attributes",
   weightCharacteristic: "Weight Characteristic",
   materials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharacteristic: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   alternates: "Alternates",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   relatedATS: "Related ATS",
   organization: "Organizations",
   fileSection: "Files",
   gpsAssessments: "GPS Assessments",
   certifications: "Certifications",
   // masterAttributes: "Master Attributes",
   // manufacturerEquivalents: "Manufacturer Equivalents",
   // derivedParts: "Derived Parts",
   // substitutions: "Substitues",
   // supplierEquivalence: "Supplier Equivalents",
   sustainability: "Sustainability",
   whereUsed: "Where Used",
}

export const RMPKeys = {
   attributes: "Attributes",
   profileIdentification: "Profile/Identification",
   physicalProperties: "Physical Properties",
   perfumeProperties: "Perfume Properties",
   chemicalMolecularProperties: "Chemical/Molecular Properties",
   detergentSurfactantProperties: "Detergent Surfactant Properties",
   batteryDetails: "Battery Details",
   weightCharacteristic: "Weight Characteristic",
   chemicalClassification: "Chemical Classification",
   stabilityDocument: "Stability Document",
   materialsAndComposition: "Materials & Composition",
   relatedSpecifications: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocument: "Reference Documents",
   performanceCharcteristic: "Performance Characteristic",
   halbPlants: "Plants",
   rohPlants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View – Supplier Equivalents",
   certifications: "Certifications",
   packagingCertificationsMaster:"Packaging Certifications",
   alternates: "Alternates",
   relatedATS: "Related ATS",
   producingFormula: "Producing Formula",
   startingMaterial: "Starting Materials",
   rdsites: "R&D Sites",
   lifeCycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownerShip: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizations: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   sustainability: "Sustainability",
   
   // reach: "REACH",
   // plants: "Plants",
   // manufacturerEquivalents: "Manufacturer Equivalents",
   // supplierEquivalents: "Supplier Equivalents",
   // masterAttributes: "Master Attributes",
   substancesMaterials: "Materials & Composition",
   // notes: "Notes",
   // derivedParts: "Related Parts",
   whereUsed: "Where Used"
}

export const SEPKeys = {
   attributeObject: "Attributes",
   reach: "REACH",
   stabilityDocument: "Stability Document",
   materialsAndComposition: "Materials & Composition",
   substancesMaterials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   enterpriseParts: "Enterprise Parts",
   equivalentMEP: "Equivalent MEP",
   certifications: "Certifications",
   relatedATS: "Related ATS",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   packagingCertifications: "Certifications",
   whereUsed: "Where Used",
}

export const TUPKeys = {
   attributes: "Attributes",
   notes: "Notes",
   billOfMaterials: "Bill Of Materials",
   substitutes: "Substitutes",
   bomMasterCustomerUnit: "Bill of Materials Master Customer Unit",
   bomMasterInnerPack: "Bill of Materials Master Inner Pack ",
   substitutesMasterInnerPack: "Substitutes Master Inner Pack",
   bomMasterConsumerUnit: "Bill of Materials Master Consumer Unit",
   // substitutesMasterCustomerUnit: "Substitutes Master Customer Unit",
   substitutesMasterConsumerUnit: "Substitutes Master Consumer Unit",
   relatedSpecification: "Related Specifications",
   referenceDocument: "Reference Documents",
   relatedATS: "Related ATS",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownership: "Ownership",
   ipclasses: "IP Classes",
   securityClasses: "Security Classes",
   organization: "Organizations",
   // storageTransportationLabelingAssessmentData: "Storage, Transportation, Labeling Assessment Data",
   //  derivedParts: "Derived Parts",
   fileSection: "Files",
   whereUsed: "Where Used",
}

export const IRMSKeys = {
   attributeObject: "Attributes",
   profileIdentification: "Profile/Identification",
   chemicalClassification: "Chemical Classification",
   physicalProperties: "Physical Properties",
   perfumeProperties: "Perfume Properties",
   chemicalProperties: "Chemical/Molecular Properties",
   detergentProperties: "Detergent Surfactant Properties",
   reach: "Reach",
   stabilityDocument: "Stability Document",
   substanceMaterials: "Substances & Materials",
   relatedSpecification: "Related Specifications",
   masterSpecifications: "Master Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   alternates: "Alternates",
   halbplants: "Plants",
   rohPlants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View – Supplier Equivalents",
   relatedATS: "Related ATS",
   regulatorySafetyData: "Regulatory & Safety Data",
   producingFormulas: "Producing Formula",
   rdsites: "R&D Sites",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   substitutions: "Substitutes",
   specifications: "Specifications",
   whereUsed: "Where Used"
}

export const MPPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   reference: "Reference",
   stabilityDocument: "Stability Document",
   billOfMaterials: "Bill Of Materials",
   substitutions: "Substitutes",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristics",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   whereUsed: "Where Used"
   //    derivedParts: "Derived Parts",
}

export const MPMPKeys = {
   attributeObject: "Attributes",
   reference: "Reference",
   substancesMaterials: "Materials & Composition",
   relatedSpecification: "Related Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   whereUsed: "Where Used",
   // derivedParts: "Derived Parts",
   // billOfMaterials: "Bill Of Materials",
   // substitution: "Substitutes",
   // relatedATS: "Related ATS",
}

export const DPPKeys = {
   attributeObject: "Attributes",
   weightCharcteristic: "Weight Characteristic",
   dangerousGoodsClassification: "Dangerous Goods Classification",
   globalHarmonizedStandard: "Global Harmonized Standard",
   ingredientStatement: "Ingredient Statement",
   storageTransportationLabelingAssessmentData:
      "Storage, Transportation, Labeling Assessment Data",
   productPartStabilityDocument: "Product Part Stability Document",
   masterPartStabilityDocument: "Master Part Stability Document",
   notes: "Notes",
   sapBOMFed: "SAP BOM as Fed",
   marketClearance: "Market Clearance",
   materialsAndCompositions: "Materials & Composition",
   billOfMaterials: "Bill of Materials",
   substitutions: "Substitutes",
   relatedSpecification: "Related Specifications",
   masterSpecification: "Master Specifications",
   referenceDocuments: "Reference Documents",
   performanceCharacteristics: "Performance Characteristic",
   plants: "Plants",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   materialProduced: "Materials Produced",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClass: "Security Classes",
   relatedATS: "Related ATS",
   organizationObject: "Organizations",
   fileSection: "Files",
   gpsAssessments: "GPS Assessments",
   smartLabel: "Smart Label",
   certifications: "Certifications",
   certificationValidation: "Certification Validation",
   stabilityResult: "Stability Results",
   substancesMaterials: "Materials & Composition",
   whereUsed:"Where Used"
}

// export const CommonPagekeys={
//     attributeList: "Attributes",
//     relatedSpecifications: "Related Specifications",
//     fileViewAPI:"File Download"
// }
export const CommonPagekeys = {
   attributeList: "Attributes",
   marketsApproved: "Markets Approved",
   relatedSpecifications: "Related Specifications",
   referenceDocuments: "Reference Documents",
   relatedACS: "Related ACS",
   relatedIAPS: "Related IAPS",
   plants: "Plants",
   relatedATS: "Related ATS",
   multipleOwnershipAccess: "Multiple Ownership Access",
   relatedParts: "Related Parts",
   ipclasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationsData: "Organizations",
   Files: "Files",
   whereUsed: "Where Used",
}

export const ComIRMkeys = {
   attributeList: "Attributes",
   platFormAndChassis: "Platform and Chassis",
   Files: "Files",
   relatedParts: "Related Parts",
   relatedPartsStudyProtocol: "Related Parts",
   referenceDocuments: "Reference Documents",
   multipleOwnershipAccess: "Multiple Ownership Access",
   ipSecuritySetting: "IP Classes",
   securityClasses: "Security Classes",
   gpsAssessments: "GPS Assessments",
   whereUsed: "Where Used",
}

export const ComIRMSubAttributekeys = {
   attrAgeConditionControls: "Age and Condition Controls",
   attrStudyObjectivesSuccessCriteria: "Study Objectives and Success Criteria",
   attrStudyDesign: "Study Design",
   attrStudyClassification: "Study Classification",
   attrPanelistRecruitingCriteria: "Panelist Recruiting Criteria",
   attrSpecialProductElements: "Special Product Elements",
   attrPanelistTasks: "Panelist Tasks",
   attrGPSAssessmentType: "GPS Assessment Type",
   attrStudyLocationDates: "Study Location/Dates",
   attrAgencyTasks: "Agency Tasks",
   attrBudgetOwnerApproval: "Budget Owner Approval",
   attrGPSAssessment: "GPS Assessment",
}

export const POAKeys = {
   Attributes: "Attributes",
   relatedATS: "Related ATS",
   marketsApproved: "Markets Approved",
   relatedSpecifications: "Related Specifications",
   referenceDocuments: "Reference Documents",
   partsAssociation: "Part(s) Association",
   ipclasses: "IP Classes",
   securityclasses: "Security Classes",
   organizations: "Organizations",
   fileSection: "Files",
   whereUsed: "Where Used"
}

export const PSUBKeys = {
   attributesObject: "Attributes",
   organizationsObject: "Organizations",
   billOfMaterials: "Bill Of Materials",
   plants: "Plants",
   ownershipObject: "Ownership",
   specification: "Specifications",
   performanceCharacteristic: "Performance Specifications",
   substitutes: "Substitutes",
   referenceDocument: "Reference Documents",
   securityClaases: "Security Classes",
   ipclasses: "IP Classes",
   weightsDimensionsObject: "Weights And Dimensions",
   packingUnit: "Packing Unit",
   transportUnitHead: "Transport Unit",
   marketOfSale: "Market Of Sale",
   notes: "Notes",
   whereUsed:"Where Used",
}

export const FCKeys = {
   attributesObject: "Attributes",
   organizationsObject: "Organizations",
   finalIngredients: "Final Ingredients",
   billOfMaterials: "Bill Of Materials",
   substitutes: "Substitutes",
   performanceCharacteristic: "Performance Specifications",
   specification: "Specifications",
   relatedACS: "Related ACS",
   relatedIAPS: "Related IAPS",
   referenceDocument: "Reference Documents",
   countryClearance: "Market Clearance",
   plants: "Plants",
   ownershipObject: "Ownership",
   ipclasses: "IP Classes",
   securityClaases: "Security Classes",
   whereUsed:"Where Used",
}

export const MPSKeys = {
   attributesObject: "Attributes",
   organizationsObject: "Organizations",
   billOfMaterials: "Bill Of Materials",
   substitutes: "Substitutes",
   notes: "Notes",
   performanceCharacteristic: "Performance Specifications",
   weightsDimensionsObject: "Weights & Dimensions",
   packingUnit: "Packing Unit",
   transportUnitHead: "Transport Unit",
   Specifications: "Specifications",
   referenceDocument: "Reference Documents",
   ownershipObject: "Ownership",
   ipclasses: "IP Classes",
   securityClaases: "Security Classes",
   whereUsed:"Where Used",
}

export const IMPKeys = {
   attributesObject: "Attributes",
   materialsAndComposition: "Materials & Composition",
   referencedocuments: "Reference Documents",
   organizationObject: "Organizations",
   whereUsed: "Where Used",
}

export const MCPKeys = {
   attributesObject: "Attributes",
   materialsAndCompositions: "Materials & Composition",
   referenceDocument: "Reference Documents",
   gpsAssessments: "GPS Assessments",
   certifications: "Certifications",
   whereUsed: "Where Used",
}

export const PQRKeys = {
   attributesObject: "Attributes",
   referenceDocument: "Reference Documents",
   qualified: "Qualified Equivalents",
   plants: "Plants",
   whereUsed:"Where Used",
}

export const BSFKeys = {
   attributeObject: "Attributes",
   organizationObject: "Organizations",
   billOfMaterials: "Bill Of Materials",
   ownershipObject: "Ownership",
   substitutes: "Substitutes",
   whereUsed: "Where Used"
}

export const SISKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   performanceCharacteristic: "Performance Specifications",
   specification: "Specifications",
   referenceDocuments: "Reference Documents",
   ownerShip: "Ownership",
   ipClasses: "IP Classes ",
   securityClasses: "Security Classes",
   whereUsed: "Where Used"
}

export const SWPKeys = {
   attributeObject: "Attributes",
   notes: "Notes",
   marketClearance: "Market Clearance",
   relatedSpecification: "Related Specifications",
   referenceDocument: "Reference Documents",
   plants: "Plants",
   performanceCharacteristic: "Performance Characteristic",
   pqrViewManufacturer: "PQR View - Manufacturer Equivalents",
   pqrSupplierView: "PQR View - Supplier Equivalents",
   relatedSoftwareBuilds: "Related Software Builds",
   lifecycleApprovalPowerview: "Lifecycle/Approval Powerview ",
   ownershipObject: "Ownership",
   ipClasses: "IP Classes",
   securityClasses: "Security Classes",
   organizationObject: "Organizations",
   files: "Files",
   gpsAssessments: "GPS Assessments",
   whereUsed: "Where Used",
}

export const SMLKeys = {
   attributeObject: "Properties",
   smartLabelRow: "Smart Label Row",
   whereUsed: "Where Used",
}

export const SBVKeys = {
   generalInformation: "General Information",
   productEffectivity: "Product Effectivity",
   asdesignedPartInformation: "As-Designed Part Information",
   customerInformation: "Customer Information",
   accessControl: "Access Control",
   buildingPlanning: "Building Planning/Shipping Information",
   whereUsed: "Where Used"
}

export const CMNKeys = {
   attributeObject: "Attributes",
   characteristicInformation: "Characteristic Information",
   values: "Values",
   whereUsed: "Where Used"
}

export const CopyListKeys = {
   Attributes: "Attributes",
   whereUsed: "Where Used"
}

export const EMPKeys = {
   attributes: "Attributes",
   materialsAndComposition: "Materials & Composition",
   referenceDocuments: "Reference Documents",
   whereUsed: "Where Used"
}

export const SearchOptionValues = [
   {
      value: "gcas",
      display: "GCAS/Name",
      toolTip: "Search result(s) in Name field only.",
   },
   {
      value: "exact",
      display: "Attribute Match",
      toolTip: "Search the result(s) in Name, Title, Type and Description fields.",
   },
   {
      value: "fts",
      display: "Full Text",
      toolTip:
         "Search the result(s) in Name, Title, Type, Description and in File(s) contents.",
   },
]

export const ReportDetail = {
   reportName: "ATS Report",
   latestReleseInput: true,
   partName: "mmmm",
   reportCustomName: "dfdfd",
   templateName: "dfdfdf",
   includeChecked: true,
   fileData: "",
}

export const ProductDataType = [
   {
      value: "Empty",
      display: "--------Select Product Data Type--------",
   },
   {
      value: "pgAncillaryPackagingMaterialPart",
      display: "Ancillary Packaging Material Part",
   },
   {
      value: "pgAncillaryRawMaterialPart",
      display: "Ancillary Raw Material Part",
   },
   {
      value: "pgApprovedSupplierList",
      display: "Approved Supplier List",
   },
   {
      value: "pgArtwork",
      display: "Art",
   },
   {
      value: "pgAssembledProductPart",
      display: "Assembled Product Part",
   },
   {
      value: "pgAuthorizedConfigurationStandard",
      display: "Authorized Configuration Standard",
   },
   {
      value: "pgAuthorizedTemporarySpecification",
      display: "Authorized Temporary Standard",
   },
   {
      value: "pgConsumerUnitPart",
      display: "Consumer Unit Part",
   },
   {
      value: "pgCustomerUnitPart",
      display: "Customer Unit Part",
   },
   {
      value: "pgDeviceProductPart",
      display: "Device Product Part",
   },
   {
      value: "pgFabricatedPart",
      display: "Fabricated Part",
   },
   {
      value: "Finished Product Part",
      display: "Finished Product Part",
   },
   {
      value: "pgFinishedProduct",
      display: "Finished Product",
   },
   {
      value: "pgFormulatedProduct",
      display: "Formula Card",
   },
   {
      value: "Formulation Part",
      display: "Formulation Part",
   },
   {
      value: "pgIllustration",
      display: "Illustration",
   },
   {
      value: "pgInnerPackUnitPart",
      display: "Inner Pack",
   },
   {
      value: "Formula Technical Specification",
      display: "Intermediate Assembled Product Specification",
   },
   {
      value: "pgIntermediateProductPart",
      display: "Intermediate Product Part",
   },
   {
      value: "pgLaboratoryIndexSpecification",
      display: "Laboratory Index Specification",
   },
   {
      value: "pgMakingInstructions",
      display: "Making Instruction",
   },
   {
      value: "pgMasterConsumerUnitPart",
      display: "Master Consumer Unit Part",
   },
   {
      value: "pgMasterCustomerUnitPart",
      display: "Master Customer Unit Part",
   },
   {
      value: "pgMasterFinishedProduct",
      display: "Master Finished Product",
   },
   {
      value: "pgMasterInnerPackUnitPart",
      display: "Master Inner Pack",
   },
   {
      value: "pgMasterPackagingAssemblyPart",
      display: "Master Packaging Assembly Part",
   },
   {
      value: "pgMasterPackagingMaterialPart",
      display: "Master Packaging Material Part",
   },
   {
      value: "pgMasterPackingMaterial",
      display: "Master Packaging Material Specification",
   },
   {
      value: "pgMasterProductPart",
      display: "Master Product Part",
   },
   {
      value: "pgMasterRawMaterialPart",
      display: "Master Raw Material Part",
   },
   {
      value: "pgMasterRawMaterial",
      display: "Master Raw Material Specification",
   },
   {
      value: "pgOnlinePrintingPart",
      display: "Online Printing Part",
   },
   {
      value: "Packaging Assembly Part",
      display: "Packaging Assembly Part",
   },
   {
      value: "Packaging Material Part",
      display: "Packaging Material Part",
   },
   {
      value: "pgPackingMaterial",
      display: "Packaging Material",
   },
   {
      value: "pgPackingInstructions",
      display: "Packing Instruction",
   },
   {
      value: "pgPackingSubassembly",
      display: "Packing Subassembly",
   },
   {
      value: "POA",
      display: "POA",
   },
   {
      value: "pgProcessStandard",
      display: "Process Standard",
   },
   {
      value: "pgPromotionalItemPart",
      display: "Promotional Item Part",
   },
   {
      value: "Qualification",
      display: "Qualification (PQR)",
   },
   {
      value: "pgQualitySpecification",
      display: "Quality Specification",
   },
   {
      value: "pgRawMaterial",
      display: "Raw Material IRMS",
   },
   {
      value: "pgRawMaterialPlantInstruction",
      display: "Raw Material Plant Instruction",
   },
   {
      value: "Raw Material",
      display: "Raw Material",
   },
   {
      value: "pgSoftwarePart",
      display: "Software Part",
   },
   {
      value: "pgStackingPattern",
      display: "Stacking Pattern Specification",
   },
   {
      value: "pgStandardOperatingProcedure",
      display: "Standard Operating Procedure",
   },
   {
      value: "pgSupplierInformationSheet",
      display: "Supplier Information Sheet",
   },
   {
      value: "Test Method Specification",
      display: "Test Method Specification",
   },
   {
      value: "pgTransportUnitPart",
      display: "Transport Unit Part",
   },
]

export const qualificationState = [
   {
      value: "Approved",
      display: "Approved",
   },
   {
      value: "Qualified",
      display: "Qualified",
   },
]
export const otherState = [
   {
      value: "Release",
      display: "Release",
   },
]
export const ExpirationDate = [
   {
      value: "",
      display: "-----Select Expiration Date-----",
   },
   {
      value: "Upon Expiration",
      display: "Upon Expiration",
   },
   {
      value: "7 Days prior to Expiration",
      display: "7 Days prior to Expiration",
   },
   {
      value: "14 Days prior to Expiration",
      display: "14 Days prior to Expiration",
   },
   {
      value: "21 Days prior to Expiration",
      display: "21 Days prior to Expiration",
   },
   {
      value: "28 Days prior to Expiration",
      display: "28 Days prior to Expiration",
   },
]

export const exceptAllTabs = [
   "expandProductBom",
   "includeProductBom",
   "includePackageBom",
   "latestReleseInput",
   "includeHyperlink",
   "partName",
   "changeAction",
   "reportCustomName",
   "template",
   "fileData",
   "reportName",
   "latestRelease",
   "finalArtWorkChecked",
   "artWorkChecked",
   "exportPOAChecked",
   "pdfView",
   "reportType",
   "characteristicsApollo",
   "chemPhyProperties",
   "pgDSMIncludeMaterialCompositionfromComponentEquivalents",
]
export const exceptPdfDownload =[
   "attributes",
   "billOfMaterialsIncludesSubstitutesAllCategories",
   "relatedSpecifications",
   "masterSpecifications",
]

export const policyVisible = [
   "",
   "pgAncillaryPackagingMaterialPart",
   "pgAncillaryRawMaterialPart",
   "pgDeviceProductPart",
   "pgFabricatedPart",
   "pgIntermediateProductPart",
   "pgOnlinePrintingPart",
   "pgPackagingAssemblyPart",
   "Packaging Material Part",
   "pgPromotionalItemPart",
   "pgRawMaterial",
   "Raw Material",
   "pgSoftwarePart",
]

export const typeDropdown = {
   Organization: [{ labelKey: "organization", value: "Organization-Change Management" }],
   Vendor: [{ labelKey: "vendor", value: "Company(Restricted)" }],
   Plant: [{ labelKey: "plant", value: "Plant" }],
   Brand: [{ labelKey: "brand", value: "Brand" }],
   Segment: [{ labelKey: "segment", value: "Segment" }],
   ManuFacturingStatus: [
      { labelKey: "manufacturingStatus", value: "Manufacturing Status" },
   ],
}

export const reportTypes = [
   "ATS Report",
   "Mass Artwork Download",
   "Parts and Specification Report",
   "PDF Download",
   "Reference Document Report",
   "Certification Roll-Up",
   "Characteristic Master Report",
   "Where Used Report",
]

export const PendingViewActions = [
   {
      value: "MoveToSummary",
      display: "Accept",
   },
   {
      value: "MoveToFavorite",
      display: "Move to Favorites",
   },
   {
      value: "MoveToP&G",
      display: "Reject",
   }
]

export const SummaryViewActions = [
   {
      value: "MoveToFavorite",
      display: "Move to Favorites",
   }
]

export const FavoritesViewActions = [
   {
      value: "RemoveFromFavorite",
      display: "Remove From Favorites",
   },
]
export const commonViewColumns = [
   { label:"Name", value:"Name" },
   { label:"Type", value:"Type" },
   { label:"Spec Sub Type", value:"SpecSubType" },                
   { label:"Rev" , value:"AffRev" },                  
   { label:"Title" , value:"AffTitle" },                  
   { label:"Reason For Change", value:"ReasonForChange" },                  
   { label:"Release Date", value:"ReleaseDate" }, 
   { label:"Modified Date",value :"EBPModified" },                  
   { label:"Impacted Part(s)", value:"Impacted Part" },                  
   { label:"Type", value:"Display Type" },                  
   { label:"Rev", value:"Revision" },                  
   { label:"Title", value:"ImpactedTitle"},                 
]

export const whereUsedColumns = [
   { label:"Name", value:"Name" },
   { label:"Type", value:"Type" },
   { label:"Rev" , value:"AffRev" },                  
   { label:"Title" , value:"AffTitle" },                  
   { label:"Impacted Part(s)", value:"Impacted Part" },                  
   { label:"Type", value:"Display Type" },                  
   { label:"Rev", value:"Revision" },                  
   { label:"Title", value:"ImpactedTitle"},                 
]