import React from "react"
import CustomLink from "./CustomLink"
import { setNonDispalyValue, tableLinkColumn } from "./EventHandler"

const CustomBOMTable = (props) => {
   let data = []
   data = props.bom

   const getHeader = () => {
      let keys = getKeys()
      return keys.map((key, index) => {
         if (setNonDispalyValue(key)) {
            return null
         }
         return (
            <th key={key}>
               <span>{key}</span>
            </th>
         )
      })
   }

   const getRowsData = () => {
      let items = data
      let keys = getKeys()
      return items.map((row, index) => {
         return (
            <tr key={index}>
               <RenderRow key={index} data={row} keys={keys} />
            </tr>
         )
      })
   }

   const getKeys = function () {
      return Object.keys(data[0])
   }

   const getColumnKeys = function (columKeyInfo) {
      return Object.keys(columKeyInfo)
   }

   const getLinksArray = function (data, link, id) {
      let elements = data.map((value, index) => {
         let num = index + 1
         if (
            link[index] &&
            link[index].trim() !== "" &&
            id[index] &&
            id[index].trim() !== "" &&
            id[index].toLowerCase().trim() !== "no access"
         ) {
            return (
               <span key={index}>
                  <CustomLink
                     id={id[index].trim()}
                     link={link[index].trim()}
                     name={value}
                  />
                  {num === data.length ? "" : "|"}
               </span>
            )
         } else {
            return (
               <span key={index}>
                  {value}
                  {num === data.length ? "" : "|"}
               </span>
            )
         }
      })
      return elements
   }

   const RenderColumn = function (props) {
      return props.keys.map((key, index) => {
         if (props.data[key] != null) {
            if (
               (key.toLowerCase() === "sp" || key.toLowerCase() === "alt" || key.toLowerCase() === "ln") &&
               (props.parentKey === "substituteparts(sp)alternate(alt)" || props.parentKey === "substituteparts(sp)alternate(alt)layername(ln)")
            ) {
               let nameData = props.data[key]["name"]
                  ? props.data[key]["name"].split("|")
                  : []
               let nameLink = props.data[key]["link"]
                  ? props.data[key]["link"].split("|")
                  : []
               let nameId = props.data[key]["id"] ? props.data[key]["id"].split("|") : []
               let getnamelinks = getLinksArray(nameData, nameLink, nameId)
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>
                     <span>{getnamelinks}</span>
                  </div>
               )
            } else if (props.data[key] && setNonDispalyValue(key)) {
               return null
            } else {
               return (
                  <div key={index}>
                     <span className={props.isbold ? "bomFontClass" : ""}>{key}:</span>{" "}
                     <span>{props.data[key]}</span>
                  </div>
               )
            }
         } else return null
      })
   }
   const RenderLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            (key.toLowerCase() === "n" ||
               key.toLowerCase() === "ppn" ||
               key.toLowerCase() === "sp" ||
               key.toLowerCase() === "pn"||
               key.toLowerCase() === "tn")
         ) {
            let name = props.data[key]

            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }

         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span className={props.isbold ? "bomFontClass" : ""}>{key}: </span>{" "}
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }
   const RenderParentLinkColumn = function (props) {
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            props.data["id"] &&
            props.data["id"].toLowerCase().trim() !== "no access" &&
            (key.toLowerCase() === "name")
         ) {
            let name = props.data[key]
            return (
               <div key={index} style={{ minWidth: "9rem" }}>
                  <span>
                     {props.data["link"] ? (
                        <CustomLink
                           id={props.data["id"]}
                           link={props.data["link"]}
                           name={name}
                        />
                     ) : (
                        <span>{name}</span>
                     )}
                  </span>
               </div>
            )
         } else if (props.data[key] && setNonDispalyValue(key)) {
            return null
         }
         // else if (props.data[key])
         else if (props.data[key] != null)
            return (
               <div key={index}>
                  <span>{props.data[key]}</span>
               </div>
            )
         else return null
      })
   }

   const RenderRow = (props) => {
      var colKeys = []
      return props.keys.map((key, index) => {
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key === "Name(N) Rev(R)" ||
               key === "Name(N) Type(T)" ||
               key === "Product Part Name(PPN) Product Part Revision(PPR)"||
               key === "Parent Name(PN) Rev(R)"||
               key === "TUP Name(TN) Rev(R)")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key === "Parent Name")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderParentLinkColumn
                     isbold={true}
                     id={props.data["id"]}
                     link={props.data["link"]}
                     data={props.data[key]}
                     keys={colKeys}
                  />
               </td>
            )
         }
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key.toLowerCase().split(" ").join("") ===
               "substituteparts(sp)alternate(alt)" ||
               key.toLowerCase().split(" ").join("") ===
                  "substituteparts(sp)/alternate(alt)")
         ) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn
                     isbold={true}
                     data={props.data[key]}
                     keys={colKeys}
                     parentData={props.data}
                     parentKey="substituteparts(sp)alternate(alt)"
                  />
               </td>
            )
         }
         if (
            props.data[key] &&
            typeof props.data[key] == "object" &&
            (key.toLowerCase().split(" ").join("") ===
                  "substituteparts(sp)alternate(alt)layername(ln)" ||
               key.toLowerCase().split(" ").join("") === 
                  "substituteparts(sp)/alternate(alt)/layername(ln)"
         )) {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn
                     isbold={true}
                     data={props.data[key]}
                     keys={colKeys}
                     parentData={props.data}
                     parentKey="substituteparts(sp)alternate(alt)layername(ln)"
                  />
               </td>
            )
         }

         if (props.data[key] && typeof props.data[key] == "object") {
            colKeys = getColumnKeys(props.data[key])
            return (
               <td key={index}>
                  <RenderColumn isbold={true} data={props.data[key]} keys={colKeys} />
               </td>
            )
         } else if (
            key.toLowerCase().trim() === "tup name" &&
            props.data["tupId"] &&
            props.data["tupId"].toLowerCase().trim() !== "no access"
         ) {
            let tup_Name = props.data[key]
            return tableLinkColumn(
               props.data["tupId"],
               props.data["tupLink"],
               tup_Name,
               index
            )
         } else if (
            key.toLowerCase() === "name" &&
            props.data["link"] &&
            props.data["link"] !== ""
         ) {
            let name = props.data[key]
            return tableLinkColumn(props.data["id"], props.data["link"], name, index)
         } else if (setNonDispalyValue(key)) {
            return null
         } else if (
            key.toLowerCase().trim() === "batch/unit size" ||
            key.toLowerCase().trim() === "target" ||
            key.toLowerCase().trim() === "comment"
         ) {
            let batchValue = props.data[key] ? props.data[key].split("\n") : []
            let batchelements = batchValue.map((key, index) => {
               return (
                  <div key={index}>
                     {key.includes(":") ? (
                        <span>
                           <b>{key.split(":")[0]}</b>:{key.split(":")[1]}
                        </span>
                     ) : (
                        <span>{key}</span>
                     )}
                  </div>
               )
            })
            return <td key={index}>{batchelements}</td>
         } else {
            return <td key={index}>{props.data[key]}</td>
         }
      })
   }

   return (
      <div className="tableFixHead">
         {data && data.length > 0 && (
            <table>
               <thead
                  style={{
                     visibility: props.isHeader !== "false" ? "visible" : "collapse",
                  }}
               >
                  <tr>{getHeader()}</tr>
               </thead>
               <tbody>{getRowsData()}</tbody>
            </table>
         )}
      </div>
   )
}
export default CustomBOMTable
