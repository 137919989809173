import React, { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { isLoggedIn, notAuthorized } from "../../../api/AuthService"
import ReportSubmit from "./ReportSubmit"
import { exceptPdfDownload } from "../../../constant/Constants"
import textValidation from "./textValidation"
const selectedCheckboxes = new Set()
function PDFDownload(props) {
   const userRole=localStorage.getItem("role")
   const [data, setData] = useState({
      reportName: props.reportName,
      partName: "",
      pdfView:userRole === "CM"?"contractpackaging":"default",
      changeAction: "",
      reportCustomName: "",
      template: "",
      fileData: "",
      
   })
   const [dataAttributes,setDataAttributes]=useState({
      attributes:true,
      dgClassification:false,
      globalHarmonizedStandard:false,
      storageTransportationLabelingAssesmentData:false,
      stabilityDocument:false,
      batteryRollUp:false,
      warehouseClassification:false,
      ingredientTransparency:false,
      uniqueFormulaIdentifier:false,
      notes:false,
      marketOfSale:true,
      sAPBomAsFed:false,
      marketOfSale:false,
      billOfMaterialsIncludesSubstitutesAllCategories:true,
      performanceCharacteristics:false,
      relatedSpecifications:true,
      masterSpecifications:true,
      certifications:false,
      weightsDimensionsIncludesTransportPackingUnit:false,
      baseCodeSubstitute:false,
      relatedATS:false,
   })
   const [message, setMessage] = useState("")
   const [success, setSuccess] = useState(false)
   const [blinkSearch, setBlinkSearch] = useState("reportOptions")
   const totalItems=document.getElementsByClassName("pdfDownloadTabs").length
   const defaultChecked=document.getElementsByClassName("defaultChecked").length
   const handleRadioChange = (e) => {
      const { value } = e.target
      setData((data) => ({
         ...data,
         pdfView: value,
      }))
   }

   const handleTextChange = (e) => {
      e.preventDefault()
      setBlinkSearch("reportOptions")
      setMessage("")
      const textVal = textValidation(e.target.value, e.target.name)
      if (textVal.props.children || e.target.value === "") {
         const { name, value } = e.target
         setData((data) => ({
            ...data,
            [name]: value,
         }))
      }
   }

   const handleValidation = (code, msg) => {
      if (code === 999) {
         setSuccess(false)
         setMessage(
            "Please enter Part Names/Change Actions OR upload an Input File to request a report."
         )
         setBlinkSearch("highlightFields")
      } else if (code === 200) {
         setSuccess(true)
         setMessage(
            "Your Report will be processed in the background and will complete shortly. Please refresh the screen after few minutes"
         )
      } else if (code === 422 || code === 429 || code === 417 || code === 501) {
         setSuccess(false)
         setMessage(msg)
      } else if (code === 401) {
         notAuthorized(props.history)
      } else if (code === 423) {
         setSuccess(false)
         setMessage(
            "The maximum number of In process report requests (5) has been reached. Please submit another report later."
         )
      } else {
         setSuccess(false)
         setMessage("Oops something went wrong.Please try again later.")
      }
   }

   const clearMsg = () => {
      setMessage("")
   }

   const handleClearItems = () => {
      document.getElementById("view").value = "0"
      props.clearItem()
   }

   const fileValidation = (e) => {
      e.preventDefault()
      const filePath = e.target.value
      // Allowing file type
      var allowedExtensions = /(\.txt)$/i

      if (!allowedExtensions.exec(filePath)) {
         alert("Invalid file type. Please upload a text file.")
         e.target.value = ""
         setData((data) => ({
            ...data,
            fileData: "",
         }))
         return false
      }
      const reader = new FileReader()
      reader.onload = async (e) => {
         const text = e.target.result
         const removeNewLine = text.replace(/[\n]+/g, ",")
         const formatedText = removeNewLine.replace(/[\t]/g, " ")
         const textOnly = formatedText.replace(/[^a-zA-Z0-9 ,-]/g, "")
         setData((data) => ({
            ...data,
            fileData: textOnly
         }))
      }
      reader.readAsText(e.target.files[0])
   }

   const handleClearFile =(e) =>{
      e.preventDefault()
      var file = document.getElementById('file')
      file.value=''
      setData((data) => ({
         ...data,
         fileData: ''
      }))
   }
   const handleCheckbox = (e) => {
      setMessage("")
      const target = e.target
      const checked = target.checked
      const name = target.name
      if (name === "allCategories"){
         for (const key of Object.keys(dataAttributes)) {
            if (!exceptPdfDownload.includes(key)) {
               setDataAttributes((dataAttributes) => ({
                  ...dataAttributes,
                  [key]: checked,
               }))
               if (checked) {
                  if (!selectedCheckboxes.has(key)) {
                     selectedCheckboxes.add(key)
                  }
               } else {
                  selectedCheckboxes.clear()
               }
            }
         }
      }
      else {
         setDataAttributes((dataAttributes) => ({
            ...dataAttributes,
            [name]: checked,
            allCategories: false,
         }))

         if (checked) {
            if (!selectedCheckboxes.has(name)) {
               selectedCheckboxes.add(name)
            }
         } else {
            selectedCheckboxes.delete(name)
         }
         selectedCheckboxes.delete("allCategories")
         if([...selectedCheckboxes].length === (totalItems - defaultChecked)){
            setDataAttributes((dataAttributes) => ({
               ...dataAttributes,
               allCategories: true,
            }))
         }
      }
   }

   useEffect(() => {
      if (!isLoggedIn()) {
         props.history.push("/")
      } else {
         setData((data) => ({
            ...data,
            partName: props.templateData.partName ? props.templateData.partName : "",
            pdfView: props.templateData.pdfView ? props.templateData.pdfView:data.pdfView,
            changeAction: props.templateData.changeAction ? props.templateData.changeAction : "",
            reportCustomName: props.templateData.reportCustomName ? props.templateData.reportCustomName : "",
            reportName: props.reportName ? props.reportName : "",
            
         }))

         setDataAttributes((dataAttributes) => ({
            ...dataAttributes,
            allCategories:props.templateData.allCategories?props.templateData.allCategories:false,
            dgClassification:props.templateData.dgClassification?props.templateData.dgClassification:false,
            globalHarmonizedStandard:props.templateData.globalHarmonizedStandard?props.templateData.globalHarmonizedStandard:false,
            storageTransportationLabelingAssesmentData:props.templateData.storageTransportationLabelingAssesmentData?props.templateData.storageTransportationLabelingAssesmentData:false,
            stabilityDocument:props.templateData.stabilityDocument?props.templateData.stabilityDocument:false,
            batteryRollUp:props.templateData.batteryRollUp?props.templateData.batteryRollUp:false,
            warehouseClassification:props.templateData.warehouseClassification?props.templateData.warehouseClassification:false,
            notes:props.templateData.notes?props.templateData.notes:false,
            sAPBomAsFed:props.templateData.sAPBomAsFed?props.templateData.sAPBomAsFed:false,
            marketOfSale:props.templateData.marketOfSale?props.templateData.marketOfSale:false,
            performanceCharacteristics:props.templateData.performanceCharacteristics?props.templateData.performanceCharacteristics:false,
            
            certifications:props.templateData.certifications?props.templateData.certifications:false,
            weightsDimensionsIncludesTransportPackingUnit:props.templateData.weightsDimensionsIncludesTransportPackingUnit?props.templateData.weightsDimensionsIncludesTransportPackingUnit:false,
            relatedATS:props.templateData.relatedATS?props.templateData.relatedATS:false,
            baseCodeSubstitute:props.templateData.baseCodeSubstitute?props.templateData.baseCodeSubstitute:false,
            ingredientTransparency:props.templateData.ingredientTransparency?props.templateData.ingredientTransparency:false,
            uniqueFormulaIdentifier:props.templateData.uniqueFormulaIdentifier?props.templateData.uniqueFormulaIdentifier:false,
         }))
      }
   }, [props.templateData])

   return (
      <React.Fragment>
            <Form.Group as={Row} className="mb-3" id="pdfFormRadio">
         {userRole!=="SP" &&
               <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
                  PDF View
               </Form.Label>}
               
                  <Col sm="6" md="3">
                  {userRole==="" && (
                     <>
                     <Form.Check
                     type="radio"
                     label="Default Internal View"
                     name="pdfView"
                     id="default"
                     value="default"
                     onChange={handleRadioChange}
                     checked={data.pdfView === "default"}
                     />
                     <Form.Check
                     type="radio"
                     label="Supplier"
                     name="pdfView"
                     id="supplier"
                     value="supplier"
                     onChange={handleRadioChange}
                     checked={data.pdfView === "supplier"}
                     />
                      </>
                  )
                     }
                    {userRole!=="SP" && (<>
                     <Form.Check
                     type="radio"
                     label="Contract Manufacturing"
                     name="pdfView"
                     id="contractpackaging"
                     value="contractpackaging"
                     onChange={handleRadioChange}
                     checked={data.pdfView === "contractpackaging"}
                     />
                    <Form.Check
                     type="radio"
                     label="Consolidated Packaging View"
                     name="pdfView"
                     id="consolidatedpackaging"
                     value="consolidatedpackaging"
                     onChange={handleRadioChange}
                     checked={data.pdfView === "consolidatedpackaging"}
                     />
                    </>)} 
                  </Col>
            </Form.Group>
            {data.pdfView==="consolidatedpackaging" && ( 
               <>
             <Form.Group as={Row} className="mb-3" id="partAndSpecFormlabel">
            <Form.Label column sm="11" md="11" className="reportLabels">
              PLEASE NOTE: Selecting All Categories will result in longer processing time.
            </Form.Label>
         </Form.Group>
            <Form.Group as={Row} className="mb-3" id="allCategories">
            <Form.Label column sm="5" md="5" className="reportLabels">
               All Categories
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  name="allCategories"
                  onChange={handleCheckbox}
                  checked={dataAttributes.allCategories}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="attributes">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Attributes
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Check
                  type="checkbox"
                  className="pdfDownloadTabs defaultChecked"
                  name="attributes"
                  onChange={handleCheckbox}
                  checked={true} disabled 
               />
            </Col>
         </Form.Group>
            <Form.Group as={Row} className="mb-3" id="dgClassification">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Dangerous Goods Classification
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="dgClassification"
                     onChange={handleCheckbox}
                     checked={dataAttributes.dgClassification}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="globalHarmonizedStandard">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Global Harmonized Standard
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="globalHarmonizedStandard"
                     onChange={handleCheckbox}
                     checked={dataAttributes.globalHarmonizedStandard}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="storageTransportationLabelingAssesmentData">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Storage, Transportation, Labeling Assessment Data
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="storageTransportationLabelingAssesmentData"
                     onChange={handleCheckbox}
                     checked={dataAttributes.storageTransportationLabelingAssesmentData}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="stabilityDocument">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Stability Document
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="stabilityDocument"
                     onChange={handleCheckbox}
                     checked={dataAttributes.stabilityDocument}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="batteryRollUp">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Battery Roll Up & Calculation
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="batteryRollUp"
                     onChange={handleCheckbox}
                     checked={dataAttributes.batteryRollUp}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="warehouseClassification">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Warehouse Classification
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="warehouseClassification"
                     onChange={handleCheckbox}
                     checked={dataAttributes.warehouseClassification}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="ingredientTransparency">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Ingredient Transparency
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="ingredientTransparency"
                     onChange={handleCheckbox}
                     checked={dataAttributes.ingredientTransparency}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="uniqueFormulaIdentifier">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Unique Formula Identifier
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="uniqueFormulaIdentifier"
                     onChange={handleCheckbox}
                     checked={dataAttributes.uniqueFormulaIdentifier}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="notes">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Notes
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="notes"
                     onChange={handleCheckbox}
                     checked={dataAttributes.notes}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="sAPBomAsFed">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  SAP BOM as Fed
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="sAPBomAsFed"
                     onChange={handleCheckbox}
                     checked={dataAttributes.sAPBomAsFed}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="marketOfSale">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Market of Sale
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="marketOfSale"
                     onChange={handleCheckbox}
                     checked={dataAttributes.marketOfSale}
                  />
               </Col>
        </Form.Group>
        
        
        <Form.Group as={Row} className="mb-3" id="billOfMaterialsIncludesSubstitutesAllCategories">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Bill of Materials (includes Substitutes) - all categories
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs defaultChecked"
                     name="billOfMaterialsIncludesSubstitutesAllCategories"
                     onChange={handleCheckbox}
                     checked={true} disabled 
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="performanceCharacteristics">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Performance Characteristic
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="performanceCharacteristics"
                     onChange={handleCheckbox}
                     checked={dataAttributes.performanceCharacteristics}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="relatedSpecifications">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Related Specifications
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs defaultChecked"
                     name="relatedSpecifications"
                     onChange={handleCheckbox}
                     checked={true} disabled
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="masterSpecifications">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Master Specifications
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="masterSpecifications"
                     onChange={handleCheckbox}
                     checked={true} disabled
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="certifications">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Certifications
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
className="pdfDownloadTabs"
                     name="certifications"
                     onChange={handleCheckbox}
                     checked={dataAttributes.certifications}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="weightsDimensionsIncludesTransportPackingUnit">
               <Form.Label column sm="5" md="5" className="reportLabels">
                  Weights & Dimensions (includes Packing and Transport Units)
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="weightsDimensionsIncludesTransportPackingUnit"
                     onChange={handleCheckbox}
                     checked={dataAttributes.weightsDimensionsIncludesTransportPackingUnit}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="baseCodeSubstitute">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Base Code & Substitute Base Code Details
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="baseCodeSubstitute"
                     onChange={handleCheckbox}
                     checked={dataAttributes.baseCodeSubstitute}
                  />
               </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" id="relatedATS">
               <Form.Label column sm="5" md="5" className="reportLabels">
               Related ATS
               </Form.Label>
               <Col sm="6" md="6">
                  <Form.Check
                     type="checkbox"
                     className="pdfDownloadTabs"
                     name="relatedATS"
                     onChange={handleCheckbox}
                     checked={dataAttributes.relatedATS}
                  />
               </Col>
        </Form.Group>
        </>)}
         <Form.Group as={Row} className="mb-3" id="pdfFormlabel">
            <Form.Label column sm="11" md="11" className="reportLabels">
               Please enter Part name(s) and/or Change Action(s) below <b>OR</b> upload a
               .txt file containing Part Name(s) and/or Change Action(s).
            </Form.Label>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormPart">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Part Name
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="partName"
                  value={data.partName}
                  rows={3}
                  onChange={handleTextChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormChangeAction">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Change Action
            </Form.Label>
            <Col sm="6" md="6">
               <Form.Control
                  as="textarea"
                  name="changeAction"
                  value={data.changeAction}
                  rows={3}
                  onChange={handleTextChange}
                  className={`${blinkSearch}`}
                  style={{ minHeight: "3rem" }}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormInput">
            <Form.Label column sm="5" md="5" className="reportLabels" id="impInput">
               Input File(ANSI .txt files only)
            </Form.Label>
            <Col sm="6" md="6">
               <input type="file" id="file" onChange={fileValidation} />
               <Button variant="primary" onClick={handleClearFile}>
                  Clear
               </Button>
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormCustomnName">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Report Custom Name
            </Form.Label>
            <Col sm="6" md="6" mt="2">
               <Form.Control
                  as="input"
                  name="reportCustomName"
                  value={data.reportCustomName}
                  rows={3}
                  className="reportOptions"
                  onChange={handleTextChange}
               />
            </Col>
         </Form.Group>
         <Form.Group as={Row} className="mb-3" id="pdfFormTemplate">
            <Form.Label column sm="5" md="5" className="reportLabels">
               Save as Template (New Template will be created)
            </Form.Label>
            <Col sm="6" md="6" mt="2">
               <Form.Control
                  as="input"
                  name="template"
                  value={data.template}
                  rows={3}
                  className="reportOptions"
                  onChange={handleTextChange}
               />
            </Col>
         </Form.Group>
         <Form.Row>
            <Col md="11" sm="11" align="center">
               <p style={{ marginTop: "4px", fontWeight: "bold" }} className={(success ? 'successMsg' : 'alertMsg')}>
                  {message}
               </p>
            </Col>
         </Form.Row>
         <br />
         <ReportSubmit
            data={data.pdfView==="consolidatedpackaging" ? {...dataAttributes,...data}:{...data}}
            handleClearItems={handleClearItems}
            handler={handleValidation}
            clearMsg={clearMsg}
         />
         <br />
      </React.Fragment>
   )
}
export default PDFDownload
